



















































import { Component, Vue, Watch } from "vue-property-decorator";
import { Getter, Action } from "vuex-class";
import { Dropdown, DropdownMenu, DropdownItem } from "element-ui";

// import "@/assets/css/navbar.less";
@Component({
    components: {
        "el-dropdown": Dropdown,
        "el-dropdown-menu": DropdownMenu,
        "el-dropdown-item": DropdownItem,
    },
})
export default class NavbarUser extends Vue {
    @Getter("getUser") getUser: any; //获取store中的user的方法
    @Getter("getAssessPermission") assessPermission: any; //评审权限

    @Action("getSetAssessPermission") setAssessPermission: any; //设置store评审权限

    get user() {
        return this.getUser();
    }

    /**导航参数 */
    get navdata() {
        const assess = {
            content: this.$t("scaffold.projectAssess"),
            to: "assessList",
            isActived: ["assessList"],
            dropdown: false,
            nopadding: false,
        };

        let opt = [
            {
                content: this.$t("scaffold.homepage"),
                to: "home",
                isActived: "home",
                dropdown: false,
                nopadding: false,
            },
            {
                content: this.$t("scaffold.userinfo"),
                to: "userinfo",
                isActived: "userinfo",
                dropdown: false,
                nopadding: false,
            },
            // {
            //     content: this.$t("scaffold.projectManage"),
            //     to: "project",
            //     isActived: ["projectlist", "register", "projectBasic"],
            //     dropdown: true,
            //     nopadding: true,
            //     dropdownchild: [
            //         {
            //             to: "projectBasic",
            //             content: this.$t("scaffold.myProject"),
            //             isActived: "projectBasic"
            //         },
            //         {
            //             to: "register",
            //             content: this.$t("scaffold.projectRelease"),
            //             isActived: "register"
            //         }
            //     ]
            // },
            {
                content: this.$t("scaffold.projectManage"),
                to: "projectBasic",
                isActived: [
                    "projectlist",
                    "register",
                    "projectBasic",
                    "projectTech",
                    "projectTeam",
                    "projectFile",
                ],
                dropdown: false,
                nopadding: false,
            },
            {
                content: this.$t("scaffold.expertManage"),
                to: "expertBasic",
                isActived: ["expert", "register", "expertBasic"],
                dropdown: false,
                nopadding: false,
            },

            // { content: "订单及发票", to: "seeOrder", isActived: "seeOrder" },
            // { content: "参考资料", to: "downloadfile", isActived: "downloadfile" }
        ];

        if (this.user.project_id) {
            opt.push({
                content: "专家评论",
                to: {
                    name: "projectComments",
                    params: { pid: this.user.project_id },
                } as any,
                isActived: "projectComments",
                dropdown: false,
                nopadding: false,
            });
        } else {
            const i = opt.findIndex(
                (val: any) => val.to.name === "projectComments"
            );
            if (i !== -1) opt.splice(i, 1);
        }

        if (this.assessIsShow) {
            opt.push(assess);
        } else {
            let i = opt.findIndex((val: any) => val.to === assess.to);
            if (i != -1) {
                opt.splice(i, 1);
            }
        }
        return opt;
    }

    // assessIsShow: boolean = false; //导航是否显示评审

    /**导航是否显示评审 */
    get assessIsShow() {
        if (this.assessPermission && this.assessPermission.length > 0) {
            return true;
        } else {
            return false;
        }
    }

    actived = this.$route.name; //导航当前选中项

    /**导航点击事件 */
    handleNav(e: any, item: any, index: any) {
        if (item.dropdown === false) {
            if (item.to == "register") {
                this.$router.push({ name: item.to, params: { step: "1" } });
            } else if (item.to == "assessList") {
                this.$router.push({
                    name: item.to,
                    params: {
                        eid: this.user.expert_id,
                        id: this.assessPermission[0].review_id,
                    },
                });
            } else {
                if (item.to.name) this.$router.push(item.to);
                else this.$router.push({ name: item.to });
            }
        }
    }

    /**子导航点击事件 */
    command(to: any) {
        if (to == "register") {
            this.$router.push({ name: to, params: { step: "1" } });
        } else {
            this.$router.push({ name: to });
        }
    }

    @Watch("$route")
    routeChange(to: any, from: any) {
        this.actived = to.name;
    }

    // haveAssessPermission() {
    //     if (this.assessPermission && this.assessPermission.length > 0) {
    //         this.showAssess();
    //     } else {
    //         this.getAssessPermission();
    //     }
    // }

    /**获取评审权限 */
    getAssessPermission() {
        const eid = this.user.expert_id;
        if (eid) {
            const getOpt = this.$api.apiList.getAssessPermission;
            const opt = getOpt(eid);
            this.$axios({
                url: opt.url,
                method: opt.method,
                headers: opt.headers,
            })
                .then((res: any) => {
                    this.setAssessPermission(res.data.data);
                })
                .catch((err: any) => {
                    console.log(err);
                    // this.$notify({
                    //     title: "警告",
                    //     message: "获取评审权限失败",
                    //     type: "warning"
                    // });
                });
        }
    }

    // showAssess() {
    //     //导航栏显示评审
    //     this.assessIsShow = true;
    // }

    // hideAssess() {
    //     //导航栏隐藏评审
    //     this.assessIsShow = false;
    // }

    created() {}

    mounted() {
        // this.haveAssessPermission();
        // this.showAssess();
        // console.log(this.navdata[2].isActived.indexOf(this.actived))
        // console.log((this.actived == this.navdata[2].isActived)||(this.navdata[2].isActived.isArray&&this.navdata[2].isActived.indexOf(this.actived)!=-1))
        // console.log(Array.isArray(this.navdata[2].isActived));
    }
}
